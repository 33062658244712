/* color
---------------------------------------------------------- */
/* breakpoint
---------------------------------------------------------- */
.top-mainvisual {
  width: 100%;
  position: relative; }
  @media (min-width: 641px) {
    .top-mainvisual .bg-main {
      position: relative;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      height: 420px;
      background-image: url(/img/top/mainvisual_pc.jpg); } }
  @media (max-width: 640px) {
    .top-mainvisual .bg-main {
      width: 100%; }
      .top-mainvisual .bg-main img {
        width: 100%;
        height: auto; } }
  .top-mainvisual .ttl-slogan {
    position: absolute;
    top: 24%;
    left: 50%;
    width: 100%;
    padding: 0 15px;
    transform: translateX(-50%);
    text-align: center; }
    @media (min-width: 641px) {
      .top-mainvisual .ttl-slogan {
        top: 128px; } }
    .top-mainvisual .ttl-slogan img {
      width: auto;
      max-width: 100%; }

section.banner {
  margin: 0 auto;
  padding: 20px; }
  section.banner .banner-pr {
    position: relative;
    margin-bottom: 10px; }
    section.banner .banner-pr a {
      display: block; }
      @media (min-width: 641px) {
        section.banner .banner-pr a {
          opacity: 1;
          transition: opacity 0.2s ease 0s; }
          section.banner .banner-pr a:hover {
            opacity: 0.7;
            text-decoration: none; } }
  section.banner .box-store {
    display: flex;
    margin: 0 -5px; }
    @media (min-width: 641px) {
      section.banner .box-store {
        flex-direction: column; } }
    section.banner .box-store .item {
      margin: 0 5px; }
      section.banner .box-store .item:hover {
        opacity: .8; }
      section.banner .box-store .item a {
        display: block; }
        @media (min-width: 641px) {
          section.banner .box-store .item a {
            opacity: 1;
            transition: opacity 0.2s ease 0s; }
            section.banner .box-store .item a:hover {
              opacity: 0.7;
              text-decoration: none; } }
  section.banner img {
    width: 100%; }
  @media (min-width: 641px) {
    section.banner {
      padding: 30px 15px;
      display: flex;
      justify-content: space-between;
      max-width: 990px; }
      section.banner .banner-pr {
        width: 76%; }
      section.banner .box-store {
        margin: -5px 0;
        width: 24%;
        padding-left: 2%; }
        section.banner .box-store .item {
          margin: 5px 0;
          cursor: pointer; }
          section.banner .box-store .item img {
            width: 100%; } }

section.notice {
  background: rgba(155, 114, 175, 0.1);
  padding: 0 30px 20px 30px; }
  @media (min-width: 641px) {
    section.notice {
      padding: 10px 15px 30px; } }
  section.notice .ttl-notice {
    font-size: 2.8rem;
    color: #E861A4;
    text-align: center;
    line-height: 2.1;
    margin-bottom: 15px; }
  section.notice .list-news {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px; }
    section.notice .list-news .item {
      color: #4a4a4a;
      background: #fff;
      padding: 10px 15px;
      margin-bottom: 13px; }
      section.notice .list-news .item .txt-title {
        font-size: 1.6rem;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: 6px;
        border-bottom: 2px solid #e1e1e1; }
      section.notice .list-news .item .txt-date {
        font-size: 1.2rem;
        margin-bottom: 10px; }
      section.notice .list-news .item .txt-desc {
        font-size: 1.4rem; }
      section.notice .list-news .item a {
        color: #e45744;
        text-decoration: underline; }
        section.notice .list-news .item a:hover {
          color: #ff5e48; }
    @media (min-width: 641px) {
      section.notice .list-news {
        flex-direction: row;
        margin: 0 auto 30px auto;
        max-width: 990px; }
        section.notice .list-news .item {
          width: 300px;
          margin: 0 15px; } }
  section.notice .btn-notice {
    font-size: 1.4rem;
    text-align: center;
    color: #fff;
    line-height: 3.1;
    margin: 0 auto;
    width: 200px;
    background: url(/img/common/btn-notice_01.png) no-repeat;
    background-size: 100% 100%;
    cursor: pointer; }
    section.notice .btn-notice:hover {
      background: url(/img/common/btn-notice_02.png) no-repeat;
      background-size: 100% 100%; }

section.feature .box {
  position: relative;
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer; }
  section.feature .box a {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%; }
  @media (max-width: 640px) {
    section.feature .box {
      background-size: 100%;
      padding: 0;
      padding-bottom: 100%; }
      section.feature .box.business {
        background-image: url(/img/top/img_service_sp.jpg); }
      section.feature .box.about {
        background-image: url(/img/top/img_aboutus_sp.jpg); }
      section.feature .box.careers {
        background-image: url(/img/top/ing_careers_sp.jpg); } }
  @media (min-width: 641px) {
    section.feature .box {
      height: 310px;
      background-size: cover; }
      section.feature .box:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        opacity: 0; }
      section.feature .box.business {
        background-image: url(/img/top/img_service_pc.jpg); }
      section.feature .box.about {
        background-image: url(/img/top/img_aboutus_pc.jpg); }
      section.feature .box.careers {
        background-image: url(/img/top/ing_careers_pc.jpg); }
      section.feature .box .wrap-width {
        max-width: 990px;
        margin: 0 auto;
        position: relative;
        height: 100%;
        width: 100%;
        transition: opacity .2s; }
      section.feature .box:hover:before {
        opacity: 1; }
      section.feature .box:hover .txt-caption {
        background: #8F4AB1; } }
  @media (max-width: 1024px) {
    section.feature .box .wrap-width {
      padding-left: 0 !important; } }
  section.feature .box .txt-subject {
    position: absolute;
    top: 95px;
    left: 20px;
    width: 200px; }
    @media (max-width: 640px) {
      section.feature .box .txt-subject {
        width: 185px;
        top: 50px;
        left: 30px; } }
    section.feature .box .txt-subject img {
      width: 100%;
      height: auto; }
  section.feature .box .txt-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(155, 114, 175, 0.8);
    color: #fff;
    font-size: 1.6rem;
    padding: 12px 0;
    width: 100%; }
    @media (max-width: 640px) {
      section.feature .box .txt-caption {
        padding: 12px 15px; } }
