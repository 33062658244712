@import '../_variables.scss';
@import '../_mixin.scss';

$width-screen: 990px;

.top-mainvisual {
  width: 100%;
  position: relative;

  .bg-main {
    @include mq-pc {
    position: relative;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 420px;
    background-image: url(/img/top/mainvisual_pc.jpg);
    }
    @include mq-sp {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .ttl-slogan {
    position: absolute;
    top: 24%;
    left: 50%;
    width: 100%;
    padding: 0 15px;
    transform: translateX(-50%);
    text-align: center;

    @include mq-pc {
      top: 128px;
    }

    img {
      width: auto;
      max-width: 100%;
    }
  }
}

section.banner {
  margin: 0 auto;
  padding: 20px;

  .banner-pr {
    position: relative;
    margin-bottom: 10px;

    a {
      display: block;

      @include is-opacity;
    }    
  }

  .box-store {
    display: flex;
    margin: 0 -5px;

    @include mq-pc {
      flex-direction: column
    }

    .item {
      margin: 0 5px;
      &:hover{
        opacity: .8;
      }

      a {
        display: block;

        @include is-opacity;
      }
    }
  }
  img {
    width: 100%;
  }
  @include mq-pc {
    padding: 30px 15px;
    display: flex;
    justify-content: space-between;
    max-width: $width-screen;
    .banner-pr {
      width: 76%;
    }
    .box-store {
      margin: -5px 0;
      width: 24%;
      padding-left: 2%;

      .item {
        margin: 5px 0;
        cursor: pointer;

        img {
          width: 100%;
        }
      }
    }
  }

}

section.notice {
  background: rgba(155, 114, 175, .1);
  padding: 0 30px 20px 30px;
  @include mq-pc {
    padding: 10px 15px 30px;
  }
  .ttl-notice {
    font-size: 2.8rem;
    color: $color-pink;
    text-align: center;
    line-height: 2.1;
    margin-bottom: 15px;
  }

  .list-news {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    .item {
      color: $color-grey;
      background: #fff;
      padding: 10px 15px;
      margin-bottom: 13px;

      .txt-title {
        font-size: 1.6rem;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: 6px;
        border-bottom: 2px solid #e1e1e1;
      }

      .txt-date {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      .txt-desc {
        font-size: 1.4rem;
      }

      a {
        color: $color-orange;
        text-decoration: underline;

        &:hover {
          color: #ff5e48;
        }
      }
    }
    @include mq-pc {
      flex-direction: row;
      margin: 0 auto 30px auto;
      max-width: $width-screen;
      .item{
        width: 300px;
        margin: 0 15px;
      }
    }
  }

  .btn-notice {
    font-size: 1.4rem;
    text-align: center;
    color: #fff;
    line-height: 3.1;
    margin: 0 auto;
    width: 200px;
    background: url(/img/common/btn-notice_01.png) no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
      background: url(/img/common/btn-notice_02.png) no-repeat;
      background-size: 100% 100%;
    }
  }

}

section.feature {
  .box {
    position: relative;
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;

    a {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
    }

    @include mq-sp {
      background-size: 100%;
      padding: 0;
      padding-bottom: 100%;
      &.business {
        background-image: url(/img/top/img_service_sp.jpg);
      }

      &.about {
        background-image: url(/img/top/img_aboutus_sp.jpg);
      }

      &.careers {
        background-image: url(/img/top/ing_careers_sp.jpg);
      }
    }
    @include mq-pc {
      height: 310px;
      background-size: cover;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        opacity: 0;
      }
      
      &.business {
        background-image: url(/img/top/img_service_pc.jpg);
      }

      &.about {
        background-image: url(/img/top/img_aboutus_pc.jpg);
      }

      &.careers {
        background-image: url(/img/top/ing_careers_pc.jpg);
      }

      .wrap-width {
        max-width: $width-screen;
        margin: 0 auto;
        position: relative;
        height: 100%;
        width: 100%;
        transition: opacity .2s;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
        .txt-caption {
          background: #8F4AB1;
        }
      }
    }

    @include mq-mx(1024) {
      .wrap-width {
        padding-left: 0 !important;
      }
    }

    .txt-subject {
      position: absolute;
      top: 95px;
      left: 20px;
      width: 200px;
      @include mq-sp{
        width: 185px;
        top: 50px;
        left: 30px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }

    .txt-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(155, 114, 175, .8);
      color: #fff;
      font-size: 1.6rem;
      padding: 12px 0;
      width: 100%;


      @include mq-sp {
        padding: 12px 15px;
      }
    }
  }
}